<template>
    <div class="menu_page">
        <h1 class="page_title">
            Меню
        </h1>
        <div class="menu_wrap" :class="dragging && 'dragging'">
            <draggable 
                v-model="routersList"
                :forceFallback="true"
                ghost-class="ghost"
                tag="menu"
                class="drag_menu"
                draggable=".a_i"
                :options="{delay: 400}"
                group="setting_menu"
                @start="dragging = true"
                @end="dragging = false">
                <li 
                    v-for="link in routersList" 
                    :key="link.name"
                    class="a_i">
                    <div v-if="link.apiLink" :key="link.name" class="truncate flex items-center app_link" @click="openModal(link)">
                        <div class="icon flex items-center mr-3">
                            <i class="fi" :class="link.icon" />
                        </div>
                        <div class="label truncate">
                            {{ link.title }}
                        </div>
                    </div>
                    <router-link 
                        v-else
                        :to="{ name: link.name }" 
                        class="truncate flex items-center">
                        <div class="icon flex items-center mr-3">
                            <i class="fi" :class="link.icon" />
                        </div>
                        <div class="label truncate">
                            {{ link.title }}
                        </div>
                    </router-link>
                </li>
            </draggable>
        </div>

        <a-modal
            title=""
            :visible="visible"
            :footer="false"
            wrapClassName="ser_modal"
            :afterClose="afterClose"
            @cancel="visible = false">
            <a-spin :spinning="loading">
                <a v-for="link in menuList" :key="link.code" :href="link.url" target="_blank">
                    {{ link.name }}
                </a>
                <div v-if="empty" class="empty">Нет данных</div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
import draggable from "vuedraggable"
export default {
    components: {
        draggable
    },
    computed: {
        footerRoute() {
            return [...this.$store.state.navigation.routerList].filter(f => f.isShowMobile && f.isFooter)
        },
        hideRoute() {
            return [...this.$store.state.navigation.routerList].filter(f => !f.isShowMobile)
        },
        routersList: {
            get() {
                return [...this.$store.state.navigation.routerList].filter(f => f.isShowMobile)
            },
            set(val) {
                this.$store.dispatch('navigation/changeRouterList', [...this.footerRoute, ...val, ...this.hideRoute])
            }
        }
    },
    data() {
        return {
            dragging: false,
            visible: false,
            empty: false,
            loading: false,
            menuList: []
        }
    },
    methods: {
        afterClose() {
            this.menuList = []
            this.empty = false
        },
        openModal(item) {
            this.visible = true
            this.getMenuLinks(item)
        },
        async getMenuLinks(item) {
            try {
                this.empty = false
                this.loading = true
                const { data } = await this.$http.get(item.apiLink, {
                    params: item.params
                })
                if(data?.length) {
                    this.menuList = data
                } else {
                    this.empty = true
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
.ser_modal{
    .ant-modal-body{
        padding-top: 50px;
    }
    .empty{
        font-size: 20px;
        text-align: center;
    }
    a{
        font-size: 20px;
        word-break: break-word;
        display: block;
        &:not(:last-child){
            margin-bottom: 15px;
        }
    }
}
</style>

<style lang="scss" scoped>
.app_link{
    cursor: pointer;
}
.menu_page{
    padding: 15px;
    .page_title{
        margin-bottom: 15px;
    }
    .menu_wrap{
        background: #fff;
        border-radius: var(--borderRadius);
        .drag_menu{
            padding: 0px;
            margin: 0px;
            li{
                list-style: none;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none;   /* Chrome/Safari/Opera */
                -khtml-user-select: none;    /* Konqueror */
                -moz-user-select: none;      /* Firefox */
                -ms-user-select: none;       /* Internet Explorer/Edge */
                user-select: none;  
                &:not(:last-child){
                    border-bottom: 1px solid var(--borderColor);
                }
                .app_link,
                a{
                    color: #354052;
                    padding: 15px 0px;
                    font-weight: 300;
                    .label{
                        font-size: 20px;
                    }
                    .icon{
                        font-size: 22px;
                        animation-delay: -0.65; 
                        animation-duration: .20s 
                    }
                }
            }
        }
        &.dragging{
            li{
                &:nth-child(2n) {
                    .icon{
                        animation-name: JiggleEffect;
                        animation-iteration-count: infinite;
                        transform-origin: 50% 10%;
                        -webkit-transition: all .2s ease-in-out;
                    }
                }
                &:nth-child(2n-1) {
                    .icon{
                        animation-name: JiggleEffect2;
                        animation-iteration-count: infinite;
                        animation-direction: alternate;
                        transform-origin: 30% 5%;
                    }
                }
            }
        }
    }
}
</style>
